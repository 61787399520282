<template>
  <div id="home">
    <Welcome></Welcome>
    <UrlInput v-on:submit="onSubmit($event)" :input="currentUrl"></UrlInput>
    <v-divider class="pb-4"></v-divider>
    <UrlList :urls="urls"></UrlList>
  </div>
</template>

<script>
import Welcome from "@/components/Welcome";
import UrlInput from "@/components/UrlInput";
import UrlList from "@/components/UrlList";
import router from "@/router";

export default {
  name: 'Home',
  components: {UrlList, UrlInput, Welcome},
  data() {
    return {
      urls: [],
      currentUrl: '',
      snackbar: {
        open: false,
        message: undefined,
        color: undefined
      }
    }
  },
  mounted() {
    if (localStorage.getItem('urls')) {
      this.urls = JSON.parse(localStorage.getItem('urls'))
    }
  },
  watch: {
    urls(values) {
      localStorage.setItem('urls', JSON.stringify(values))
    }
  },
  methods: {
    onSubmit(url) {
      this.$store.$db().model('link').api().post(`${process.env.VUE_APP_API_ROOT}/link/`, {
        url: url
      }).then(rsp => {
        console.log(rsp)
        const payload = {
          url: rsp.response.data.url,
          short: rsp.response.data.short,
          created_at: rsp.response.data.created_at
        }
        let urls = this.urls
        if (urls == null) {
          this.urls = [payload]
        } else {
          urls.push(payload)
          this.urls = urls
        }
        this.currentUrl = `https://affx.eu/${payload.short}`
      }).catch(err => {
        this.showSnackbar(err.toString(), 'red', 10000)
        console.error(err)
      })
    },
    showSnackbar(message, color, duration = 3000) {
      this.snackbar.color = color
      this.snackbar.message = message
      this.snackbar.open = true
      setTimeout(() => {
        this.snackbar.open = false
      }, duration)
    }
  },
  beforeRouteEnter(to, from, next) {
    // next()
   //  return
    if (router.app.$keycloak.authenticated) {
      router.push({name: 'manage_index'})
    } else {
      next()
    }
  }
}
</script>
