<template>
  <div id="welcome" class="mb-4 pb-4">
    <v-banner v-if="this.$keycloak.authenticated" elevation="0">
      <p>Welcome, {{ this.$keycloak.tokenParsed.name }}</p>
      Go to the <v-btn plain exact text :to="{name: 'manage_index'}">management ui</v-btn>
    </v-banner>
    <v-banner v-else elevation="0">
      Welcome,
    </v-banner>
  </div>
</template>

<script>
export default {
  name: "Welcome"
}
</script>

<style scoped>

</style>
