<template>
  <div v-if="urls && urls !== undefined">
    <div v-if="$vuetify.breakpoint.xsOnly">
      <v-virtual-scroll
          :items="urls"
          item-height="200"
          height="600"
          bench="1"
      >
        <template #default="{ item }">
          <v-card :key="item.short" rounded elevation="1">
            <v-card-text>
              {{ item.url }}
            </v-card-text>
            <v-card-text class="blue--text">
              https://affx.eu/{{item.short}}
            </v-card-text>
            <v-card-text>
              <v-btn :ref="`button${item.short}`" color="blue lighten-4" small block @click="copyText($event, item.short)">Copy</v-btn>
            </v-card-text>
          </v-card>
        </template>
      </v-virtual-scroll>
    </div>
    <div v-else>
      <v-virtual-scroll
          :items="urls"
          item-height="50"
          height="500"
          bench="1"
      >
        <template #default="{ item }">
          <v-list-item :key="item.short">
            <v-list-item-content>{{ item.url }}</v-list-item-content>
            <v-spacer></v-spacer>
            <v-list-item-content>
              <v-list-item-title class="blue--text">
                https://affx.eu/{{ item.short }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :ref="`button${item.short}`" color="blue lighten-4" small @click="copyText($event, item.short)">
                Copy
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </div>
  </div>
</template>

<script>
export default {
  name: "UrlList",
  props: {
    urls: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    copyText(e, short) {
      navigator.clipboard.writeText(`https://affx.eu/${short}`)
      this.$refs[`button${short}`].color = 'green lighten-4'
      e.target.innerText = 'Copied'
      setTimeout(() => {
        this.$refs[`button${short}`].color = 'blue lighten-4'
        e.target.innerText = 'Copy'
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>
